.react-datepicker__day.react-datepicker__day--selected {
    /* background-color: #CB1A23; */
    background-color: black;
}
.react-datepicker__view-calendar-icon input{
    padding: 8px 6px 8px 35px !important;
    min-width: 195px;
    width: 100%;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
}

.react-datepicker__view-calendar-icon input:focus-visible {
    border: 1px solid black;
    outline: 0;
}

.react-datepicker-popper{
    z-index: 3 !important;
}