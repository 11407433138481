
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
Basic Quill Editor Styles
 */
.my-quill-editor .ql-editor {
  min-height: 200px; /* Ajusta esta altura según tus necesidades */
  height: 200px; /* Ajusta esta altura según tus necesidades */
}

.my-quill-editor .ql-editor a {
  color: #2000ff; /* Color verde para los enlaces en el editor */
  text-decoration: none; /* Opcional: quitar el subrayado del enlace */
}

.content-preview a {
  color: #2000ff; /* Color verde para los enlaces en la vista previa */
  text-decoration: none; /* Opcional: quitar el subrayado del enlace */
}

.my-quill-editor  .ql-toolbar.ql-snow {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
.my-quill-editor  .ql-container.ql-snow {
  border: 0;
}